
export function formatDate(time: number, seperator1: string, seperator2: string) {
    const date = new Date(time * 1000);
    let month: string = (date.getMonth() + 1).toString();
    let strDate: string = date.getDate().toString();
    if (Number(month) >= 0 && Number(month) <= 9) {
        month = "0" + month;
    }
    if (Number(strDate) >= 0 && Number(strDate) <= 9) {
        strDate = "0" + strDate;
    }
    let hours: string = date.getHours().toString();
    if (Number(hours) >= 0 && Number(hours) <= 9) {
        hours = "0" + hours;
    }
    let minutes: string = date.getMinutes().toString();
    if (Number(minutes) >= 0 && Number(minutes) <= 9) {
        minutes = "0" + minutes;
    }
    let seconds: string = date.getSeconds().toString();
    if (Number(seconds) >= 0 && Number(seconds) <= 9) {
        seconds = "0" + seconds;
    }
    const currentDate = date.getFullYear() + seperator1 + month + seperator1 + strDate
        + " " + hours + seperator2 + minutes + seperator2 + seconds;
    return currentDate;
}

export function formatDateTwo(time: number, seperator1: string) {
    const date = new Date(time * 1000);
    let month: string = (date.getMonth() + 1).toString();
    if (Number(month) >= 0 && Number(month) <= 9) {
        month = "0" + month;
    }
    const currentDate = date.getFullYear() + seperator1 + month;
    return currentDate;
}
export function formatsecd2Text(time: number) {
    let dateText: string = "0min"
    if (time >= 60) {
        const h: number = time / 60 / 60;
        const min: number = time%(3600) / 60;
        const sec: number = time%(60);
        if(h < 10){
            dateText = "0" + Math.floor(h) + ":";
        } else{
            dateText = Math.floor(h)+ ":";
        }
        if (min < 10) {
            dateText += "0" + Math.floor(min) + ":";
        } else {
            dateText += Math.floor(min)+ ":";
        } 
        if (sec < 10) {
            dateText += "0" + sec;
        } else {
            dateText += sec;
        } 
    } else if(time >= 10){
        
        dateText = "00:00:" + time;
    } else {
        dateText = "00:00:0" + time;
    }
    return dateText;
}
export function checkEmail(email: string) {
    const reg: RegExp = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
    if (reg.test(email)) {
        return true
    } else {
        return false
    }
}

export function checkPassword(password: string) {
    const reg: RegExp = /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[^A-z0-9]+$)^.{6,16}$/
    if (reg.test(password)) {
        return true
    } else {
        return false
    }
}

export function jsonParse(str: string) {
    return new Promise(function (resolve, reject) {
        const jsonObject = JSON.parse(str);
        resolve(jsonObject);
    });
}