














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TipDialog extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  private tipName!: string; //提示
  @Prop({
    type: Boolean,
    default: false,
  })
  public show!: boolean; 

  private clickConfirm() {
      this.$emit("clickConfirm");
  }

  private clickCancel() {
      this.$emit("clickCancel");
  }
}
