




























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UpdateDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private show!: boolean;
  @Prop({
    type: String,
    default: "",
  })
  private robotName!: string;
  @Prop({
    type: String,
    default: "",
  })
  private seriesNumber!: string;

  private clickConfirm() {
    this.$emit("clickConfirm", this.robotName, this.seriesNumber);
  }

  private clickCancel() {
    this.$emit("clickCancel");
  }
}
