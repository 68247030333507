import request from '@/utils/request';
import { CommonListRes, CommonRes } from './common';
import { Page } from './common';

/*
{
  "usb_disk_exist": false,
  "waste_water": 0,  //污水容量
  "clean_water": 0,  //剩余水量
  "robot_pose": {  //机器人位置，用于显示在地图图片上
    "y": 141,
    "x": 107,
    "theta": -1.04
  },
  "robot_state": 0,  //机器人当前状态，离线后端改为-1
  "slam_state": 2,  //定位状态
  "task_name": "Station_666",
  "location_desc": "广东省深圳市南山区",   //GPS定位
  "signal_quality_4g": 0,
  "error_code": [],
  "location": "",  //经纬度
  "battery_status": {
    "capacity": 0,
    "temperature": 0,
    "power_supply_technology": 0,
    "design_capacity": 0,
    "power_supply_health": 0,
    "current": 0,
    "charge": 0,
    "voltage": 0,
    "time_estimated": 0,
    "power_supply_status": 0,
    "percentage": 100,  //电量百分比
    "present": false
  },
  "map_name": "UI",  //当前地图名称，获取地图图片用
  "user_name": "",
  "signal_quality_wifi": 0
}

#slam_state（定位状态）
未开启定位 = 0
定位中 = 1
定位成功 = 2
定位信号弱 = 3
构图中 = 4

#robot_state（机器人当前状态）
离线 = -1
待机 = 0
构图中 = 1
工作中 = 2
暂停中 = 3
紧急停机 = 4
碰撞急停 = 5
录制路径中 = 6
录制路径暂停 = 7
取消任务中 = 8

*/

// 废弃
export interface TaskInfo {
    cleanMode: number;
    pathName: string;
}

// 废弃
export interface CurCombineInfo {
    cleanMode: number;
    cur_task_index: number;
    taskInfo: TaskInfo[];
    taskName: string;
}

// 废弃
export interface CurNavgatePoint {
    x: number;
    y: number;
}

// 废弃
export interface RobotStatObject {
    GPS_signal_intensity: number;
    all_clean_area: number;
    all_clean_count: number;
    all_clean_time: number;
    angle_speed: number;
    appointmentCleanRunning: boolean;
    battery: number;
    chargeStatus: number;
    cleanState: number;
    cur_combineInfo: CurCombineInfo;
    cur_mapName: string;
    cur_navgate_point: CurNavgatePoint;
    cur_pathName: string;
    emergencyStop: boolean;
    externCleanMode: number;
    handleControl: boolean;
    is_collosion: boolean;
    is_dushbin_empty: boolean;
    is_slam_success: boolean;
    liner_speed: number;
    loadmap: boolean;
    location: boolean;
    mileage: number;
    userConfirmLocationState: number;
    userName: string;
    workStatuString: string;
    workStatus: number;
}

export interface RobotPose {
    y: number;
    x: number;
    theta: number;
}

export interface BatteryStatus {
    capacity: number;
    temperature: number;
    power_supply_technology: number;
    design_capacity: number;
    power_supply_health: number;
    current: number;
    charge: number;
    voltage: number;
    time_estimated: number;
    power_supply_status: number;
    percentage: number;
    present: boolean;
}

export interface RobotNewStatObject {
    waste_water: number;
    clean_water: number;
    robot_pose: RobotPose;
    robot_state: number;
    slam_state: number;
    task_name: string;
    location_desc: string;
    signal_quality_4g: number;
    error_code: any[];
    location: string;
    battery_status: BatteryStatus;
    map_name: string;
    user_name: string;
    signal_quality_wifi: number;
}


export interface GetRobotListReq {
    page: number;
    limit: number;
    name?: string;
}

export interface RobotEntity {
    name: string;
    robotId: string;
    stateText?: string;
    routerText?: string;
    robotType?: string;
}

export interface Robot {
    robotEntity: RobotEntity;
    robotStat?: any;
    routerInfo?: any;
    robotId?: string;
}
export interface RobotStyle {
    id: number;
    robotStyle: string;
}
export interface BindRobotReq {
    robotId: string;
}

export interface UpdateRobotReq {
    name: string;
    robotId: string;
}

export interface UnBindRobotReq {
    robotId: string;
}

export interface GetRobotByIdReq {
    robotId: string;
}

export interface GetRobotByIdRes {
    msg: string;
    code: number;
    robot: Robot;
}



/**
 * 搜索已绑定的机器人列表
 *
 * @export
 * @returns {Promise<CommonListRes<Page<Robot>>>}
 */
export function getRobotList(params: GetRobotListReq): Promise<CommonListRes<Page<Robot>>> {
    return request.get<CommonListRes<Page<Robot>>>('/robot/search', params);
}
/**
 * 搜索机器人样式
 *
 * @export
 * @returns {Promise<CommonListRes<Page<Robot>>>}
 */
export function getRobotStyleList(): Promise<CommonListRes<Page<RobotStyle>>> {
    return request.get<CommonListRes<Page<RobotStyle>>>('/robot/robotStyle/list');
}

/**
 * 绑定机器人
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function bindRobot(params: BindRobotReq): Promise<CommonRes> {
    return request.post<CommonRes>('/robot/bind/user', params);
}

/**
 * 修改机器人
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function updateRobot(params: UpdateRobotReq): Promise<CommonRes> {
    return request.patch<CommonRes>('/robot', params);
}

/**
 * 解绑机器人
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function unbindRobot(params: UnBindRobotReq): Promise<CommonRes> {
    return request.post<CommonRes>('/robot/unbind/user', params);
}

/**
 * 根据机器人id获取机器人详情
 *
 * @export
 * @returns {Promise<GetRobotByIdRes>}
 */
 export function getRobotById(params: GetRobotByIdReq): Promise<GetRobotByIdRes> {
    return request.get<GetRobotByIdRes>('/robot/'+params.robotId, null);
}

/**
 * 根据机器人id获取机器人详情
 *
 * @export
 * @returns {Promise<GetRobotByIdRes>}
 */
 export function remoteRobot(params: GetRobotByIdReq): Promise<GetRobotByIdRes> {
    return request.post<GetRobotByIdRes>('/robot/remote/?robotId='+params.robotId, null);
}